import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from ".";
import Survey from "../models/survey";
import { api } from "./api";
import SurveyQuestion from "../models/surveyQuestion";
import SurveyStakeholder from "../models/surveyStakeholder";
import SurveyMetric from "../models/surveyMetric";

export interface SurveyState {
  isLoading: boolean;
  error?: string;
  survey?: Survey;
  questions?: SurveyQuestion[];
}

const initialState: SurveyState = {
  isLoading: false,
};

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addUpdateMetric: (state, action: PayloadAction<SurveyMetric>) => {
      if (state.survey) {
        const old = state.survey?.metrics?.filter(d => d.id !== action.payload.id) ?? [];
        state.survey.metrics = [...old, action.payload];
      }
    },
    addUpdateStakeholder: (state, action: PayloadAction<SurveyStakeholder>) => {
      if (state.survey) {
        const old = state.survey?.stakeholders?.filter(d => d.id !== action.payload.id) ?? [];
        state.survey.stakeholders = [...old, action.payload];
      }
    },
    updateSurvey: (state, action: PayloadAction<Survey>) => {
      if (state.survey && state.survey.id === action.payload.id) {
        state.survey = {
          ...state.survey,
          ...action.payload,
        };
      }
    },
    addUpdateQuestion: (state, action: PayloadAction<SurveyQuestion>) => {
      if (state.survey) {
        state.survey.questions = state.survey.questions?.map((q) => {
          if (q.id === action.payload.id) {
            return {
              ...q,
              ...action.payload
            }
          }
          return q;
        })
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurvey.pending, (state) => {
        return {
          isLoading: true
          // no survey/other state
        };
      })
      .addCase(fetchSurvey.rejected, (state, action) => {
        return {
          isLoading: false,
          error: action.error.message ?? 'Error fetching survey',
          // no survey/other state
        };
      })
      .addCase(fetchSurvey.fulfilled, (state, action) => {
        return {
          ...state,
          isLoading: false,
          survey: action.payload,
        };
      })
      // Questions
      .addCase(fetchQuestions.pending, (state) => {
        return {
          ...state,
          questions: undefined,
        };
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        return {
          ...state,
          error: action.error.message ?? 'Error fetching survey questions',
          // no survey/other state
        };
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        return {
          ...state,
          questions: action.payload,
        };
      })
  },
});

export const { addUpdateStakeholder, addUpdateMetric, addUpdateQuestion, updateSurvey } = surveySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSurvey = (state: RootState) => state.survey;

export default surveySlice.reducer;


export const fetchSurvey = createAsyncThunk('survey/fetch', async (id: number) => {
  return await api.surveys.get(id);
});
export const fetchQuestions = createAsyncThunk('survey/fetchQuestions', async (id: number) => {
  return await api.surveys.questions(id);
});
export const saveSurveyStakeholder = (surveyId: number, surveyStakeholder: SurveyStakeholder): AppThunk => {
  return async (dispatch, getState) => {
    if (surveyStakeholder.id) {
      const response = await api.survey(surveyId).stakeholders.update(surveyStakeholder);
      dispatch(addUpdateStakeholder(response));
    }
    else {
      const response = await api.survey(surveyId).stakeholders.create(surveyStakeholder);
      dispatch(addUpdateStakeholder(response));
    }
  };
};

export const saveSurveyQuestion = (surveyId: number, surveyQuestion: SurveyQuestion): AppThunk => {
  return async (dispatch, getState) => {
    if (surveyQuestion.id) {
      const response = await api.survey(surveyId).questions(surveyQuestion.id).update(surveyQuestion);
      dispatch(addUpdateQuestion(response));
    }
  };
};


export const saveSurvey = (surveyId: number, survey: Survey): AppThunk => {
  return async (dispatch, getState) => {
    const response = await api.survey(surveyId).update(survey);
    dispatch(updateSurvey(response));
  };
};