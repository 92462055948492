import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { config } from '../config';
import ShareResponse from '../models/shareResponse';
import Project from '../models/project';
import { Menu } from './menu';

interface IdentityResponse {
	id?: string;
	firstName?: string;
	lastName?: string;
	fullName?: string;
	email?: string;
	phoneNumber?: string;
	initials?: string;
	timeZone?: string;
	userName?: string;
	roles?: string[];
}

export interface SharedState {
	menuItems?: Menu[];
	projectId?: number;
	project?: Project;
}

export interface SessionState extends IdentityResponse {
	isLoggedIn: boolean;
	loading: boolean;
	error: any;
	environment?: string;
	shared?: SharedState;
}

const initialState: SessionState = {
	isLoggedIn: false,
	loading: true,
	error: null,
};


export const sessionSlice = createSlice({
	name: 'session',
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		hydrate: (state, action) => {
			// do not do state = action.payload it will not update the store
			return action.payload
		},
		loginRequired: (state) => {
			state.isLoggedIn = false;
			state.loading = false;
			state.error = null;
		},
		loggedIn: (state, action: PayloadAction<number>) => {
			state.isLoggedIn = false;
			state.loading = false;
			state.error = null;
		},
	},
	// The `extraReducers` field lets the slice handle actions defined elsewhere,
	// including actions generated by createAsyncThunk or in other slices.
	extraReducers: (builder) => {
		builder
			.addCase(logout.pending, (state) => {
				state.loading = true;
			})
			.addCase(logout.fulfilled, () => {
				return {
					...initialState,
					isLoggedIn: false,
					loading: false,
				};
			})
			.addCase(logout.rejected, (state) => {
				return {
					...initialState,
					isLoggedIn: false,
					loading: false,
					error: "Error logging out",
				};
			})
			.addCase(environment.fulfilled, (state, action) => {
				console.log(action.payload);
				return {
					...state,
					environment: action.payload,
				};
			})
			.addCase(identity.pending, (state) => {
				state.loading = true;
			})
			.addCase(identity.fulfilled, (state, action) => {
				return {
					...state,
					loading: false,
					isLoggedIn: true,
					...action.payload,
				};
			})
			.addCase(identity.rejected, (state) => {
				return {
					...initialState,
					isLoggedIn: false,
					loading: false,
					error: "Error getting identity",
				};
			})
			.addCase(shared.pending, (state) => {
				state.loading = true;
			})
			.addCase(shared.fulfilled, (state, action) => {
				return {
					...state,
					loading: false,
					isLoggedIn: true,
					shared: action.payload,
				};

			})
			.addCase(shared.rejected, (state) => {
				return {
					...initialState,
					isLoggedIn: false,
					loading: false,
					error: "Error accessing Shared resource",
				};
			})

			;
	},
});


export const { loginRequired, loggedIn, hydrate } = sessionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectSession = (state: RootState) => state.session;
//export const selectAccount = (state: RootState) => state.session.account;

export const logout = createAsyncThunk('session/logout', async () => {
	const authHeader = new Headers();
	authHeader.append("Content-Type", "application/json");
	const requestOptions: RequestInit = {
		method: "POST",
		headers: authHeader,
		credentials: 'include',
	};
	const response = await fetch(`${config.apiUrl}/v1/identity/logout`, requestOptions);
	return response.ok;
});

export const identity = createAsyncThunk('session/identity', async () => {
	const authHeader = new Headers();
	authHeader.append("Content-Type", "application/json");
	const requestOptions: RequestInit = {
		method: "GET",
		headers: authHeader,
		credentials: 'include',
	};
	const response = await fetch(`${config.apiUrl}/v1/identity`, requestOptions);
	if (response.ok) {
		return (await response.json()) as IdentityResponse;
	}
	return Promise.reject(await response.json());
});

export const environment = createAsyncThunk('session/environment', async () => {
	const authHeader = new Headers();
	authHeader.append("Content-Type", "application/json");
	const requestOptions: RequestInit = {
		method: "GET",
		headers: authHeader,
		credentials: 'include',
	};
	const response = await fetch(`${config.apiUrl}/v1/identity/environment`, requestOptions);
	return await response.text();
});

export const shared = createAsyncThunk('session/shared', async (token: string) => {
	const authHeader = new Headers();
	authHeader.append("Content-Type", "application/json");
	const requestOptions: RequestInit = {
		method: "POST",
		headers: authHeader,
		credentials: 'include',
		body: JSON.stringify({ token }),
	};
	const response = await fetch(`${config.apiUrl}/v1/identity/shared`, requestOptions);
	if (response.ok) {
		return (await response.json()) as ShareResponse;
	}
	return Promise.reject(await response.json());
});

export default sessionSlice.reducer;
