import { useAppSelector } from "../../store/hooks";
import { selectLabels } from "../../store/labelsSlice";
import Project from "../../models/project";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { api } from "../../store/api";
import { useEffect, useRef, useState } from "react";
import EntityTypeLabel from "../../components/label/entityTypeLabel";
import EntityTypes from "../../models/entityTypes";
import ProjectGrade from "../../models/projectGrade";
import GradeUnit from "../../models/gradeUnit";
import { Panel, PanelBody, PanelHeader } from "../../components/panel/panel";
import { format } from "../../helpers/format";
import * as Highcharts from 'highcharts';
import highchartsTreegraph from "highcharts/modules/treegraph";
import highchartsTreemap from "highcharts/modules/treemap";
import highchartsExporting from "highcharts/modules/exporting";
import highchartsMore from "highcharts/highcharts-more";
import highchartsHeatmap from "highcharts/modules/heatmap";
import HighchartsReact from "highcharts-react-official";
import highchartsDrilldown from "highcharts/modules/drilldown";
import { ReportUnit } from "../../models/reportUnit";
import { Link, generatePath, useSearchParams } from "react-router-dom";
import { setTitle } from "../../util/useDocumentTitle";
import { ProjectDetailsRoute } from "../projects/projectDetailsScreen";
import { RequiresPermission } from "../../components/role/requiresRole";
import { PermissionTypes } from "../../models/permissionTypes";
import { selectConfig } from "../../store/configSlice";
import Tag from "../../models/tag";
import { Point } from "highcharts";

export const UnitReportRoute = "/reports/unit";
type UnitReportScreenProps = {
  project?: Project;
};

export default function UnitReportScreen(props: UnitReportScreenProps) {
  const labels = useAppSelector(selectLabels);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectIdStr = searchParams.get('project');
  // const location = useLocation();

  highchartsHeatmap(Highcharts);
  highchartsTreemap(Highcharts);
  highchartsTreegraph(Highcharts);
  highchartsDrilldown(Highcharts);
  highchartsExporting(Highcharts);
  highchartsMore(Highcharts);
  highchartsDrilldown(Highcharts);

  const [hideSelector, setHideSelector] = useState(false);

  const [project, setProject] = useState<Project>();
  const [projects, setProjects] = useState<Project[] | undefined>([]);
  const [grade, setGrade] = useState<ProjectGrade>();
  const [grades, setGrades] = useState<ProjectGrade[]>([]);
  const [availableUnits, setAvailableUnits] = useState<GradeUnit[]>();
  const [selectedGradeUnits, setSelectedGradeUnits] = useState<GradeUnit[]>();
  const [units, setUnits] = useState<GradeUnit[]>();
  const [allUnits, setAllUnits] = useState(true);

  const [error, setError] = useState<String>();

  useEffect(() => {
    if (props.project) {
      setProject(props.project);
    }
  }, [props.project]);

  useEffect(() => {
    if (projectIdStr) {
      api.projects.get(parseInt(projectIdStr)).then((project) => {
        setProject(project);
        setSelectedGradeUnits([]);
      });
    }
  }, [projectIdStr]);

  useEffect(() => {
    if (project) {
      api.project(project.id!).grades().then(setGrades);
    }
    else {
      setGrades([]);
      setAvailableUnits(undefined);
      setSelectedGradeUnits(undefined);
      setUnits(undefined);
    }
  }, [project]);

  useEffect(() => {
    console.log("fetching units", project, grade);
    if (project?.id && grade?.grade) {
      api.project(project.id!).grade(grade!.grade).units().then((units) => {
        setAvailableUnits(units);
        setSelectedGradeUnits([]);
      });
    }
    else {
      setUnits(undefined);
    }
  }, [grade, project]);


  // Title
  useEffect(() => {
    setTitle([
      project ? project.name : '',
      grade ? `Grade ${labels.grades[grade.grade]}` : '',
      `Unit Report`,
    ]);
  }, [grade, labels.grades, project]);

  const canGenerateReport = grade
    && (allUnits || (selectedGradeUnits?.length ?? 0) > 0);

  const onGenerateReport = (): React.MouseEventHandler | undefined => {
    //setIsLoading(true);
    if (availableUnits) {
      if (allUnits) {
        setUnits(availableUnits);
      }
      else {
        setUnits(availableUnits.filter(t => selectedGradeUnits?.some(sel => t.id === sel.id) ?? false))
        // setUnits(selectedGradeUnits);
      }
    }
    else {
      setUnits(undefined);
    }
    return undefined;
  };


  const toggleSelectedUnit = (unit: GradeUnit) => {
    if (selectedGradeUnits) {
      if (selectedGradeUnits.find(t => t.id === unit.id)) {
        // remove
        setSelectedGradeUnits(selectedGradeUnits.filter(t => t.id !== unit.id));
      }
      else {
        setSelectedGradeUnits([...selectedGradeUnits, unit]);
      }
    }
    else {
      setSelectedGradeUnits([unit]);
    }
  };


  return (<div>
    <div className="d-flex align-items-center mb-3">
      <div>
        <h1 className="page-header mb-0">Unit Reporting</h1>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">Reports</li>
          {!hideSelector && project && props.project === undefined && <li className="breadcrumb-item"><Link to={generatePath(ProjectDetailsRoute, { id: `${project?.id}` })} >{project.name}</Link></li>}
          <li className="breadcrumb-item active">Unit Report</li>
          {grade && <li className="breadcrumb-item active">Grade {labels.grades[grade.grade]}</li>}
        </ul>
      </div>
    </div>
    {error && <div className="alert alert-danger"><strong>Error!</strong> {error}</div>}
    <div className="row">
      {hideSelector && <div className="col">
        <button className="p-0 btn" onClick={() => setHideSelector(false)}><i className="fas fa-gear"></i></button>
      </div>}
      {!hideSelector && <div className="col">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none d-flex flex-row justify-content-between align-items-center">
            <h6>Report Parameters</h6>
            <button className="p-0 btn" onClick={() => setHideSelector(true)}><i className="fas fa-eye-slash"></i></button>
          </div>
          <div className="card-body">
            <RequiresPermission permission={PermissionTypes.ProjectSearch}>
              <div className="row mb-15px">
                <label className="form-label col-form-label col-xl-3 text-nowrap"><EntityTypeLabel entityType={EntityTypes.Project} singlular /></label>
                <div className="col-xl-9">
                  <AsyncTypeahead
                    options={projects ?? []}
                    //disabled={partner === undefined}
                    id="projectDropDown"
                    labelKey={"name"}
                    filterBy={['name']}
                    selected={project ? [project] : undefined}
                    minLength={0}
                    isLoading={projects === undefined}
                    clearButton={true}
                    onSearch={function (search: string): void {
                      setProjects(undefined);
                      api.projects.search({ search }).then((result) => {
                        setProjects(result.records);
                      })
                        .catch((reason) => {
                          throw new Error("Error searching for " + labels.project.plural + ": " + reason);
                        });
                    }}
                    onChange={(selected) => {
                      console.warn('onChange', selected);
                      const selectedProjects = selected as Project[];
                      if (selectedProjects.length > 0) {
                        setProject(selectedProjects[0]);
                      }
                      else {
                        setProject(undefined);
                      }
                    }}
                  />
                </div>
              </div>
            </RequiresPermission>
            <div className="row mb-15px">
              <label className="form-label col-form-label col-xl-3 text-nowrap">Grade</label>
              <div className="col-xl-9">
                {(grades?.length ?? 0) > 0 ? <Typeahead
                  options={grades}
                  id="gradeDropDown"
                  // labelKey={"name"}
                  labelKey={(option) => {
                    const projectGrade = option as ProjectGrade;
                    return labels.grades[projectGrade.grade];
                  }}
                  filterBy={['name']}
                  selected={grade ? [grade] : undefined}
                  minLength={0}
                  isLoading={grades === undefined}
                  clearButton={true}
                  onChange={(selected) => {
                    console.warn('onChange grade', selected);
                    const selectedGrades = selected as ProjectGrade[];
                    if (selectedGrades.length > 0) {
                      setGrade(selectedGrades[0]);
                    }
                    else {
                      setGrade(undefined);
                    }
                  }}
                /> : <input className="form-control" disabled />}
              </div>
            </div>
            {availableUnits && <div className="row mb-15px">
              <label className="form-label col-form-label col-xl-3 text-nowrap">Unit(s)</label>
              <div className="col-form-label col-xl-9">
                {availableUnits === undefined && <p className="col-form-label"><i className="fa-solid fa-spin fa-spinner"></i> Loading units for grade...</p>}
                {availableUnits && <div className="form-check form-switch mb-2">
                  <input className="form-check-input" type="checkbox" id="allUnits" checked={allUnits} onChange={() => setAllUnits(!allUnits)} />
                  {allUnits ? <label className="form-check-label" htmlFor="allUnits">All Units</label> : <label className="form-check-label" htmlFor="allUnits">Selected Unit(s)</label>}
                </div>}

                {!allUnits && availableUnits?.map((unit) => <div key={unit.unit} className="form-row my-1">
                  <div className="form-check ms-auto mb-0">
                    <input type="checkbox" id={`unit-${unit.id}`} className="form-check-input" checked={selectedGradeUnits?.some((s) => s.id === unit.id) ?? true} onChange={() => toggleSelectedUnit(unit)} />
                    <label className="form-check-label" htmlFor={`unit-${unit.id}`}>{unit.unit}</label>
                  </div>
                </div>)}
              </div>
            </div>}
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-sm btn-success" disabled={!canGenerateReport} onClick={onGenerateReport}>Generate Report</button>
          </div>
        </div>
      </div>}
      <div className={hideSelector ? "col-md-12" : "col-md-8"}>
        {project && units && units.map(unit => <UnitReport key={unit.id} project={project} unit={unit} />)}
      </div>
    </div>
  </div>);
}
type UnitReportProps = {
  project: Project;
  unit: GradeUnit;
};
function UnitReport(props: UnitReportProps) {
  const labels = useAppSelector(selectLabels);
  const { project, unit } = props;
  const [isLoading, setIsLoading] = useState(false);

  //const [anchor, setAnchor] = useState<ReportUnitResource>();
  const [data, setData] = useState<ReportUnit[]>();

  useEffect(() => {
    if (unit) {
      setIsLoading(true);
      api.reports.unit(unit).data().then((data) => {
        setData(data);
        setIsLoading(false);
      });
    }
    else {
      setData(undefined);
    }
  }, [unit]);

  // Process report data
  // useEffect(() => {
  //   if (data) {
  //     setAnchor(data.resources.find((pr) => pr.isAnchor));
  //   }
  //   else {
  //     setAnchor(undefined);
  //   }
  // }, [data]);

  return (<Panel isLoading={isLoading} >
    <PanelHeader>Grade {labels.grades[unit.grade]} Unit {unit.unit}</PanelHeader>
    <PanelBody>
      {data?.map((anchorReport) => <div>
        <div className="row" id={`unit-${unit.id}`}>
          {data && <div className="col-xl-3 col-md-6">
            <div className="widget widget-stats bg-cyan h-100">
              <div className="stats-icon"><i className="fa fa-books"></i></div>
              <div className="stats-info">
                <h4>Total {labels.resource.plural}</h4>
                <p>{format.number(anchorReport.resources.length)}</p>
              </div>
              {/* <div className="stats-link">
                    <Link to="/projects">View {labels.project.plural} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                  </div> */}
            </div>
          </div>}
          {anchorReport?.anchor && <div className="col-xl-8 col-md-6">
            <div className="widget widget-stats bg-gradient-cyan-blue h-100">
              <div className="stats-icon"><i className="fa fa-anchor"></i></div>
              <div className="stats-info">
                <h4>Anchor {labels.resource.singular}</h4>
                <p>{anchorReport?.anchor.name}</p>
              </div>
              {/* <div className="stats-link">
                    <Link to="/projects">View {labels.project.plural} <i className="fa fa-arrow-alt-circle-right"></i></Link>
                  </div> */}
            </div>
          </div>}
        </div>

        {anchorReport && <QualityForUnit key={'quanity_' + unit.id} project={project} unit={unit} data={anchorReport} />}

        {anchorReport && <HeatmapForUnit key={'heatmap_' + unit.id} project={project} unit={unit} data={anchorReport} />}
      </div>)}
    </PanelBody>
  </Panel>);
}

type QualityForUnitProps = {
  project: Project;
  unit: GradeUnit;
  data: ReportUnit;
};
function QualityForUnit(props: QualityForUnitProps) {
  const labels = useAppSelector(selectLabels);
  const { project, unit, data } = props;
  const [isLoading, setIsLoading] = useState(true);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    if (project) {
      setIsLoading(true);
      //api.reports.heatmap(project.id!).heatmap(domain).then((heatmap) => {
      setChartOptions({
        // chart: {
        //   polar: true,
        //   // type: 'line',
        // },
        chart: {
          height: 100 + data.resources.length * 34,
          // events: {
          //   load: function () {
          //     var chart = this,
          //       barsLength = chart.series[0].data.length;

          //     chart.update({
          //       chart: {
          //         height: 100 + 40 * barsLength
          //       }
          //     }, true, false, false);
          //   }
          // }
        },

        title: {
          text: 'Quality vs. Topic Unity',
          //text: `Grade ${labels.grades[unit.grade]} Unit ${unit.unit}`
        },
        pane: {
          //size: '85%',
        },
        xAxis: {
          categories: data.resources.map(resource => resource.name),
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          layout: 'vertical'
        },
        yAxis: [
          {
            id: 'unity',
            title: {
              text: "Topics in common",
            },
            min: 0,
            max: data.anchor?.topics.length ?? 4,
            // tickInterval: 1,
            lineWidth: 1,
            // alignTicks: true,
            endOnTick: true,
            // tickAmount: data.anchor?.topics.length ?? 4,
            tickInterval: 1,
            allowDecimals: false,
            alignTicks: false,
            gridLineWidth: 0,
          },
          {
            min: 0,
            max: 100,
            endOnTick: true,
            allowDecimals: false,
            id: 'quality',
            title: {
              text: "Quality Score",
            },
            lineWidth: 1,
            opposite: true,
            alignTicks: false,
          },
        ],
        series: [
          // {
          //   type: 'column',
          //   name: 'Domain Unity',
          //   data: data.resources.map(resource => (data.anchor?.domains ?? 0) - resource.domainsInCommon),
          //   //[8, 7, 6, 5, 4, 3, 2, 1],
          //   //pointPlacement: 'between'
          // },

          {
            yAxis: 1,
            type: 'bar',
            name: 'Quality',
            data: data.resources.map(resource => resource.score),
            color: 'blue',
            // pointPlacement: 0.1,
            // pointWidth: 1,
            tooltip: {
              valueSuffix: '%',
            },
          },
          {
            yAxis: 0,
            type: 'bar',
            // borderRadius: 10,

            // borderRadius: 24,
            name: 'Topic Unity',
            description: 'Number of topics',
            //data: data.resources.map(resource => (data.anchor?.topics ?? 0) - resource.topicsInCommon),
            data: data.resources.map(resource => resource.topicsInCommon.length),
            color: 'orange',
            // pointPlacement: -0.1,
            // pointWidth: 1,
            tooltip: {
              valuePrefix: 'Matching ',
              valueSuffix: ' of ' + (data.anchor?.topics.length ?? 0),
              // valuePrefix: 'Not matching ',
              // valueSuffix: ' out of ' + (data.anchor?.topics ?? 0),
            },
          },
        ]

      });

      setIsLoading(false);
    }
    else {
      setChartOptions(undefined);
    }
  }, [data.anchor?.topics, data.resources, labels.grades, project, unit.grade, unit.unit]);

  return <>
    {/* <h3>{domain.label}</h3> */}
    {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading Quality and Topic Unity data...</p>}
    {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
  </>;
}

function HeatmapForUnit(props: QualityForUnitProps) {
  const labels = useAppSelector(selectLabels);
  const tags = useAppSelector(selectConfig).tags;
  const { project, unit, data } = props;
  const [isLoading, setIsLoading] = useState(true);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  // const [topics, setTopics] = useState<string[]>();
  const [topics, setTopics] = useState<Tag[]>();

  const unique = (array: string[]) => array
    .filter((value, index, self) => self.indexOf(value) === index);

  useEffect(() => {
    if (project && topics) {
      setIsLoading(true);
      //api.reports.heatmap(project.id!).heatmap(domain).then((heatmap) => {
      setChartOptions({
        chart: {
          // type: 'heatmap',
          marginTop: 40,
          marginBottom: 200,
          height: topics.length > 10 ? (topics.length * 40) + 100 : null,
          // events: {
          //   load: function () {
          //     var chart = this,
          //       barsLength = chart.series[0].data.length;

          //     chart.update({
          //       chart: {
          //         height: 100 + 40 * barsLength
          //       }
          //     }, true, false, false);
          //   }
          // }
          events: {
            drillup: (e) => {
              console.log('drillup', e);
              const chart = e.target; //this as Highcharts.Chart;
              chart.update({
                chart: {
                  height: topics.length > 10 ? (topics.length * 40) + 100 : null,
                }
              })
              chart.yAxis[0].update({
                title: {
                  text: "Topic"
                },
                categories: topics.map(t => {
                  return t.label ?? ''
                }),
                labels: {
                  autoRotation: [-90,],
                  style: {
                    textOverflow: 'none'
                  },
                },
                gridLineColor: '#000',
                gridLineWidth: 1,
                gridZIndex: 4,
              });
            },
            drilldown: async (e) => {
              console.log('drilldown', e);
              if (e.point?.y !== undefined) {
                const chart = e.target; //this as Highcharts.Chart;

                // Show the loading label
                if (e.point) {
                  const drilldownPoint = e.point;
                  console.log('drilldown point', drilldownPoint);
                  if (drilldownPoint.y !== undefined) {
                    const tagForPoint = topics[drilldownPoint.y];
                    console.log('drilldown tag', tagForPoint);
                    chart.showLoading('Loading drilldown...');

                    const heatmapDrilldownData = await api.reports.unit(props.unit).heatmap(tagForPoint.id!);
                    console.log('drilldown heatmapDrilldownData', heatmapDrilldownData);
                    chart.update({
                      chart: {
                        height: heatmapDrilldownData.subTags.length > 10 ? (heatmapDrilldownData.subTags.length * 40) + 100 : null,
                      }
                    });

                    chart.yAxis[0].update({
                      title: {
                        text: "Sub-Topic"
                      },
                      categories: heatmapDrilldownData.subTags.map(t => t.label!),
                    });
                    chart.addSeriesAsDrilldown(drilldownPoint, {
                      id: drilldownPoint.name,
                      type: 'heatmap',
                      allAreas: true,
                      dataLabels: {
                        enabled: true,
                        color: '#000000'
                      },
                      name: tagForPoint.label,
                      tooltip: {
                        pointFormatter: function (this: Point): string {
                          if (this.y !== undefined) {
                            const resource = data.resources[this.x];
                            const topic = heatmapDrilldownData.subTags[this.y];
                            const domain = tagForPoint;
                            return `${resource.name} ${(this.value ?? 0) === 0 ? 'does not have' : 'has'} tag '${domain?.label} > ${topic.label}' in common`;
                          }
                          return '';
                        },
                      },
                      data: [...heatmapDrilldownData.resources.flatMap((resource, x) => heatmapDrilldownData.subTags.map((tag, y) => {
                        const retVal = {
                          x,
                          y,
                          value: resource.topicIds.filter(t => t === tag.id).length,
                          //drilldown: `topic-${tag.id}`,
                        };
                        return retVal;
                      }),
                      )],
                    });
                    chart.xAxis[0].update({
                      labels: {
                        autoRotation: [-90,],
                        style: {
                          textOverflow: 'none'
                        }
                      },
                      gridLineColor: '#000',
                      gridLineWidth: 1,
                      gridZIndex: 4,
                    });

                    chart.hideLoading();
                  }
                }
              }
            },
          },

        },

        title: {
          text: 'Topic Heatmap',
          style: {
            fontSize: '1em'
          }
        },

        xAxis: {
          categories: data.resources.map(resource => resource.name),
          labels: {
            autoRotation: [-90,],
            style: {
              textOverflow: 'none'
            },
            formatter: function (this: Highcharts.AxisLabelsFormatterContextObject, ctx: Highcharts.AxisLabelsFormatterContextObject) { //(this: AxisLabelsFormatterContextObject, ctx: AxisLabelsFormatterContextObject) => string;
              const resource = data.resources[ctx.pos];
              if (resource.isAnchor) {
                return `<i class="fa-solid fa-anchor text-warning"></i> ${this.value}`;
              }
              return `${this.value}`;
              //return this.value.toString();
            },
            useHTML: true,
          },
          gridLineColor: '#000',
          gridLineWidth: 1,
          gridZIndex: 4,
        },

        yAxis: {
          categories: topics.map(t => {
            return t.label ?? ''
          }),
          reversed: true,
          title: {
            text: 'Topic'
          },
          gridLineColor: '#000',
          gridLineWidth: 1,
          gridZIndex: 4,
        },

        // accessibility: {
        //   point: {
        //     descriptionFormat: '{(add index 1)}. ' +
        //       '{series.xAxis.categories.(x)} sales ' +
        //       '{series.yAxis.categories.(y)}, {value}.'
        //   }
        // },

        colorAxis: {
          min: 0,
          minColor: '#FFFFFF',
          endOnTick: true,
          max: Math.max(...data.resources.flatMap((resource) => topics.map((topic, y) => resource.topicIds.filter(t => t === topic.id).length))),
        },

        legend: {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          verticalAlign: 'top',
          y: 25,
          symbolHeight: 280
        },

        drilldown: {
          // allowPointDrilldown: false,
          series: [],
          activeAxisLabelStyle: {
            textDecoration: 'none',
          },
          activeDataLabelStyle: {
            textDecoration: 'none',
          },
          breadcrumbs: {
            relativeTo: "plotBox",
            // buttonTheme: {
            //   fill: '#f7f7f7',
            //   padding: 8,
            //   stroke: '#cccccc',
            //   'stroke-width': 1
            // },
            floating: false,
            position: {
              align: 'left'
            },
            showFullPath: true
          },
        },
        series: [{
          type: 'heatmap',
          name: 'All Topics',
          // allowPointSelect: true,
          dataLabels: {
            enabled: true,
            color: '#000000'
          },
          data: [...data.resources.flatMap((resource, x) => topics.map((topic, y) => {
            return {
              x,
              y,
              value: resource.topicIds.filter(t => t === topic.id).length,
              drilldown: 'test',
            };
          }),
          )],

          tooltip: {
            pointFormatter: function (this: Point): string {
              if (this.y !== undefined) {
                const resource = data.resources[this.x];
                const tag = topics[this.y];
                const parentTag = tags?.find((d) => d.id === tag.parentTagId);
                return `${resource.name} ${(this.value ?? 0) === 0 ? 'does not have' : 'has'} tag '${parentTag?.label} > ${tag.label}' in common`;
              }
              return '';
            },
          },
        }],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            // chartOptions: {
            //   yAxis: {
            //     labels: {
            //       format: '{substr value 0 1}'
            //     }
            //   }
            // }
          }]
        }
      }
      );

      setIsLoading(false);
      //});

    }
    else {
      setChartOptions(undefined);
      console.warn('heatmap not loading', project, topics);
    }
  }, [data.resources, labels.grades, project, tags, topics, unit.grade, unit.unit]);

  useEffect(() => {
    if (data) {
      const allTopicIds = data.resources.flatMap(resource => resource.topicIds);
      const allTopics = tags
        ?.filter((tag) => allTopicIds.includes(tag.id!));
      allTopics?.sort((a, b) => {
        const parentTagA = tags?.find((aPt) => aPt.id === a.parentTagId);
        const parentTagB = tags?.find((bPt) => bPt.id === b.parentTagId);

        return (parentTagA?.sortOrder ?? 0) - (parentTagB?.sortOrder ?? 0);
      });
      setTopics(allTopics);
      // setTopics(unique(data.resources.flatMap(resource => resource.topics)));
    }
    else {
      setTopics(undefined);
    }
  }, [data, tags]);

  return <>
    {/* <h3>{domain.label}</h3> */}
    {isLoading && <p><i className="fa-solid fa-spin fa-spinner"></i> Loading Resource Heatmap...</p>}
    {chartOptions && <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartComponentRef} />}
  </>;
}