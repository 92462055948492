import { useState } from "react";
import SummaryOperationTypes from "../../models/summaryOperationTypes";
import SurveyQuestion from "../../models/surveyQuestion";
import SurveyQuestionChoice from "../../models/surveyQuestionChoice";
import { generatePath, Link } from "react-router-dom";

type QuestionBrowserProps = {
  editRoute?: string;
  parentQuestion?: SurveyQuestion;
  questions: SurveyQuestion[];
  selectedQuestionIds: number[];
  addQuestion?: (question: SurveyQuestion) => void;
  removeQuestion?: (question: SurveyQuestion) => void;
  summaryOperation?: SummaryOperationTypes;
  highlight?: string;
}

export function QuestionBrowser(props: QuestionBrowserProps) {
  const allQuestions = props.questions;
  const questions = props.questions.filter(q =>
    (props.parentQuestion === undefined ? q.parentQualtricsSurveyQuestionId === undefined : q.parentQualtricsSurveyQuestionId === props.parentQuestion.id)
  );

  if (props.parentQuestion) {
    return <>{questions.map((q) => <RenderQuestion key={`question-${q.id}`} question={q} {...props} />)}</>;
  }

  return <table className="table table-striped">
    <thead>
      <tr>
        {props.addQuestion && props.removeQuestion && <th></th>}
        <th>#</th>
        <th colSpan={2}>Domain</th>
        <th>Description</th>
        <td></td>
      </tr>
    </thead>
    <tbody>
      {questions.map((q) => <RenderQuestion key={`question-${q.id}`} question={q} {...props} />)}
    </tbody>
  </table>;
}

function getHighlightedText(text: string, highlight: string) {
  // Split text on highlight term, include term itself into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <span className="bg-yellow">{part}</span> : part)}</span>;
}

type RenderQuestionProps = {
  question: SurveyQuestion;
}

function RenderQuestion(props: RenderQuestionProps & QuestionBrowserProps) {
  const q = props.question;
  const isSelecting = (props.addQuestion && props.removeQuestion);
  const checked = props.selectedQuestionIds.includes(q.id);
  const hasSubQuestions = props.questions.find((subQuestion) => subQuestion.parentQualtricsSurveyQuestionId === q.id) !== undefined;
  const hasChoices = (q.choices?.length ?? 0) > 0;
  const enabled =
    (q.type === 'MC')
    || (props.summaryOperation === SummaryOperationTypes.Average && props.parentQuestion?.type === 'Matrix');
  //|| hasSubQuestions;

  const [showChoices, setShowChoices] = useState<boolean>(false);

  return <><tr key={`question-${q.id}`}>
    {isSelecting && <td>{enabled &&
      <input
        type="checkbox"
        className={"form-check-input " + (enabled ? '' : ' bg-dark disabled')}
        disabled={!enabled}
        id={`question-${q.id}`}
        checked={checked}
        onChange={(e) => {
          if (props.selectedQuestionIds.includes(q.id)) {
            props.removeQuestion!(q);
          }
          else {
            props.addQuestion!(q);
          }
        }}
      />}
    </td>}
    <td><label className="form-check-label mx-2" htmlFor={`question-${q.id}`} title={q.qualtricsId}>{q.name}</label></td>
    <td>{q.domain}</td><td>{q.subDomain}</td>
    <td>
      <div className="d-flex align-items-center">
        <label className="form-check-label mx-2" htmlFor={`question-${q.id}`} title={q.type}>{props.highlight ? getHighlightedText(q.text, props.highlight) : q.text}</label>
        {q.isReverseCoded && <span className="badge badge-danger bg-danger" title="Reverse coded question"><i className="fa-solid fa-arrow-down-9-1"></i></span>}
        {hasChoices && <button type="button" className={"btn btn-sm rounded-5 py-0 my-0 text-nowrap " + (showChoices ? 'btn-info' : 'btn-outline-info')} onClick={() => setShowChoices(!showChoices)}>{q.choices?.length} choices</button>}
      </div>
      {showChoices && hasChoices && <div className="ms-5">
        <table>
          <thead>
            <tr>
              <th>Text</th>
              <th>Numeric Value</th>
            </tr>
          </thead>
          {q.choices?.map((choice) => {
            return <tr key={`question-${q.id}-choice-${choice.id}`}>
              <td>{choice.choiceText}</td>
              <td>
                <span className="badge bg-success mx-1" title="Recode value">{choice.recode}</span>
                {choice.numericValue && <span className="badge bg-primary mx-1" title="Numeric value"><i className="fa-solid fa-arrow-down-1-9"></i> {choice.numericValue}</span>}
                {choice.numericValueReversed && <span className="badge bg-secondary mx-1" title="Reverse coded numeric value"><i className="fa-solid fa-arrow-down-9-1"></i> {choice.numericValueReversed}</span>}
              </td>
            </tr>
          })}
        </table>
      </div>}
    </td>
    <td>
      {props.editRoute && <Link to={generatePath(props.editRoute, { surveyQuestionId: q.id })} className="btn mx-3 text-nowrap"><i className="fa-solid fa-pencil"></i> Edit</Link>}
    </td>
  </tr>
    {hasSubQuestions && <QuestionBrowser summaryOperation={props.summaryOperation} questions={props.questions} parentQuestion={q} selectedQuestionIds={props.selectedQuestionIds} addQuestion={props.addQuestion} removeQuestion={props.removeQuestion} editRoute={props.editRoute} />}
  </>;
}
