import { useEffect, useRef, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addUpdateMetric, fetchSurvey, saveSurveyStakeholder, selectSurvey } from "../../store/surveySlice";
import { FormProvider, SubmitHandler, useFieldArray, useForm, useWatch } from "react-hook-form";
import SurveyStakeholder from "../../models/surveyStakeholder";
import { Typeahead, TypeaheadRef } from "react-bootstrap-typeahead";
import SurveyQuestion from "../../models/surveyQuestion";
import SurveyMetric from "../../models/surveyMetric";
import { QuestionBrowser } from "../../components/survey/questionBrowser";
import { api } from "../../store/api";
import SummaryOperationTypes from "../../models/summaryOperationTypes";
import useDebounce from "../../util/useDebounce";

interface DetailsRouteParams {
  surveyId?: string;
  surveyMetricId?: string;
}

export default function SurveyMetricScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;
  const isEditing = params.surveyMetricId && true;

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string>();
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<number[]>([]);
  const questions = survey?.questions; //?.filter(q => q.type === "MC");
  const [filteredQuestions, setFilteredQuestions] = useState(questions);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [prevSearchTerm, setPrevSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 800);


  const [stickyY, setStickyY] = useState<number>();
  const refSticky = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (refSticky.current) {
      const boundingRect = refSticky.current.getBoundingClientRect();
      console.log('Sticky Rect', boundingRect);
      setStickyY(boundingRect.y);
    }
  }, [refSticky]);

  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (stickyY) {
        setIsScrollValueMoreThanHeaderHeight(window.scrollY >= stickyY);
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [stickyY]);

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading && !surveyState.error) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.error, surveyState.isLoading]);

  const form = useForm<SurveyMetric>();
  const { register, handleSubmit, reset, formState: { errors }, control } = form;
  const { fields, append, remove, move } = useFieldArray({
    name: "questions",
    keyName: "id",
    control
  });

  const onSubmit: SubmitHandler<SurveyMetric> = data => {
    // console.log(data);
    // console.log('selectedQuestionIds', selectedQuestionIds);
    const request: SurveyMetric = {
      ...data,
      questions: surveyState.survey?.questions?.filter(q => selectedQuestionIds.includes(q.id)),
    };

    console.log(request);

    (isEditing ? api.survey(survey!.id).metrics.update(request) : api.survey(survey!.id).metrics.create(request))
      .then((updated) => {
        setIsSaving(false);
        //setError(undefined);
        //const path = isEditing ? '' : '#created';
        //navigate(generatePath(ReviewDetailsRoute, { id: `${updated.id}` }) + path);
        dispatch(addUpdateMetric(updated));
        navigate('../..');
      })
      .catch((reason) => {
        setError("Unable to save metric: " + reason);
        setIsSaving(false);
      });

    // dispatch(saveSurveyDimension(survey!.id, request));
    // navigate('../..');


  };

  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey) {
      let titleParts = [surveyState.survey.name, 'Surveys'];
      if (isEditing) {
        const surveyMetricId = parseInt(params.surveyMetricId);
        const edit = survey?.metrics?.find(d => d.id === surveyMetricId);
        titleParts = ['Edit Metric', ...titleParts];
        reset(edit);
        console.log('editing', edit);
        setSelectedQuestionIds(edit?.questions?.map(q => q.id) ?? []);
      }
      else {
        titleParts = ['Create Metric', ...titleParts];
      }
      setTitle(titleParts);
    }
  }, [isEditing, params.surveyMetricId, reset, survey?.metrics, surveyState.isLoading, surveyState.survey]);


  useEffect(() => {
    console.log("searching", debouncedSearchTerm);
    // filter
    if (debouncedSearchTerm.length >= 2) {
      setFilteredQuestions(questions?.filter((q) =>
        // domain match
        (q.domain?.indexOf(debouncedSearchTerm) ?? -1) >= 0
        // text match
        || (q.text.indexOf(debouncedSearchTerm) ?? -1) >= 0
      )
      );
    }
    else {
      setFilteredQuestions(questions); // all
    }
  }, [debouncedSearchTerm, questions]);

  const selectQuestion = (question: SurveyQuestion) => setSelectedQuestionIds([...selectedQuestionIds, question.id]);
  const removeQuestion = (question: SurveyQuestion) => setSelectedQuestionIds(selectedQuestionIds.filter(q => q !== question.id));

  const watchSummaryOperation = useWatch({
    control: control,
    name: `summaryOperation`,
  });

  useEffect(() => {
    // Reset form when changing the summary type
    //setSelectedQuestionIds([]);
  }, [watchSummaryOperation]);


  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Surveys</li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item"><Link to='../..'>{surveyState.survey.name}</Link></li>}
        <li className="breadcrumb-item active">Metrics</li>
      </ol>
    </div>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-3">
            {survey && <div className={"card border-0 mb-4 sticky-top " + (isScrollValueMoreThanHeaderHeight ? " shadow" : "")} ref={refSticky}>
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Metric for {survey && <i>{survey.name}</i>}
              </div>
              {survey && <div className="card-body row fw-bold">
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Metric Name</label>
                  <div className="col-auto">
                    <input type="text" className={"form-control " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} autoComplete="off" />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-2">
                  <label className="form-label col-form-label col-12">Summary Operation</label>
                  <div className="col-auto">
                    <select className={"form-control " + (errors.summaryOperation ? 'is-invalid' : '')} {...register("summaryOperation", { required: true, valueAsNumber: true })} autoComplete="off">
                      {/* <option value={SummaryOperationTypes.None}>None</option> */}
                      {/* <option value={SummaryOperationTypes.Count}>Count # of Responses</option> */}
                      <option value={SummaryOperationTypes.Average}>Average Numeric Responses</option>
                    </select>
                    {errors.summaryOperation && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
              </div>}
            </div>}
          </div>
          <div className="col-md-9">
            {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
            {survey && <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Question(s)
              </div>
              <div className="card-body">
                <div className="d-flex flex-row justify-content-between">

                  <p>
                    Responses to the question(s) below will be combined for this metric.
                  </p>
                  <div className="">
                    <div className="input-group">
                      <span className="input-group-text"><i className="fa-solid fa-filter"></i></span>
                      <input type="text" className="form-control form-control-sm" placeholder="Search/filter questions" onChange={(event) => setSearchTerm(event.target.value)} value={searchTerm} />
                      {searchTerm && <button className="btn btn-outline-danger btn-sm" onClick={() => setSearchTerm('')}><i className="fa fa-times"></i></button>}
                    </div>
                  </div>
                </div>
                {filteredQuestions && <QuestionBrowser summaryOperation={SummaryOperationTypes.Average} questions={filteredQuestions} selectedQuestionIds={selectedQuestionIds} addQuestion={selectQuestion} removeQuestion={removeQuestion} highlight={debouncedSearchTerm} />}
              </div>
            </div>}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {error && <div className="alert alert-danger"><strong>Error</strong> {error}</div>}
            <button type="submit" className="btn btn-success me-2" disabled={isSaving}>Save Metric</button>
            <Link to='../..' className="btn btn-primary">Cancel</Link>
          </div>
        </div>
      </form>
    </FormProvider>

  </div>);
}
