import { Fragment, useEffect, useRef, useState } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { addUpdateMetric, fetchSurvey, saveSurveyStakeholder, selectSurvey } from "../../store/surveySlice";
import { Controller, ControllerFieldState, ControllerRenderProps, FieldValues, FormProvider, SubmitHandler, useController, useFieldArray, UseFieldArrayAppend, useForm, useFormContext, UseFormStateReturn, useWatch } from "react-hook-form";
import SurveyStakeholder from "../../models/surveyStakeholder";
import { Typeahead, TypeaheadRef } from "react-bootstrap-typeahead";
import SurveyQuestion from "../../models/surveyQuestion";
import SurveyMetric from "../../models/surveyMetric";
import { QuestionBrowser } from "../../components/survey/questionBrowser";
import { api } from "../../store/api";
import SummaryOperationTypes from "../../models/summaryOperationTypes";
import { PartnerDropdown } from "../../components/partner/partnerDropdown";
import SurveyQuestionChoice from "../../models/surveyQuestionChoice";
import SurveyReport from "../../models/surveyReport";
import SurveyReportSchool from "../../models/surveyReportSchool";


interface DetailsRouteParams {
  surveyId?: string;
  surveyReportId?: string;
}

const getSchoolIds = (schools?: SurveyReportSchool[]): number[] => {
  return [...(schools?.flatMap((school) => getSchoolIds(school.schools)) ?? []), ...(schools?.filter((school) => school.choiceId).map((school) => school.choiceId!) ?? [])];
};

export default function SurveyReportScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;
  const isEditing = params.surveyReportId && true;

  // const addQuestionRef = useRef<TypeaheadRef>(null);
  // const addQuestionChoiceRef = useRef<TypeaheadRef>(null);
  // const [addQuestion, setAddQuestion] = useState<SurveyQuestion>();

  // const [groupSchools, setGroupSchools] = useState(false);
  const [selectedChoiceIds, setSelectedChoiceIds] = useState<number[]>([]);
  const [roles, setRoles] = useState<string[]>([
    'Principal',
    'Conference Superintendent',
    'Superintendent'
  ]);

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.isLoading]);

  const form = useForm<SurveyReport>();
  const { register, handleSubmit, reset, formState: { errors }, control, watch } = form;
  const { fields, append, remove, move } = useFieldArray({
    name: "schools",
    // keyName: "id",
    control
  });

  const onSubmit: SubmitHandler<SurveyStakeholder> = data => {
    console.warn('onSubmit', data);
    const request: SurveyStakeholder = {
      ...data,
    };
  };

  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey) {
      let titleParts = [surveyState.survey.name, 'Surveys'];
      if (isEditing) {
        const surveyStakeholderId = parseInt(params.surveyReportId);
        const edit = survey?.stakeholders?.find(d => d.id === surveyStakeholderId);
        titleParts = ['Edit Shared Report', ...titleParts];
        reset(edit);
      }
      else {
        titleParts = ['Create Shared Report', ...titleParts];
      }
      setTitle(titleParts);
    }
  }, [isEditing, params.surveyReportId, reset, survey?.stakeholders, surveyState.isLoading, surveyState.survey]);

  // const questions = survey?.questions?.filter(q => q.type === "MC");
  const schoolQuestion = survey?.questions?.find(q => q.id === survey?.schoolQuestionId);

  const watchSchools = watch('schools');

  // Update "selected" schools so they cannot be added twice
  useEffect(() => {
    if (watchSchools) {
      const getSelectedSchoolIds = getSchoolIds(watchSchools);
      setSelectedChoiceIds(getSelectedSchoolIds);
      console.log('setSelectedChoiceIds', getSelectedSchoolIds)
    }
  }, [watchSchools]);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Surveys</li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item"><Link to='../..'>{surveyState.survey.name}</Link></li>}
        <li className="breadcrumb-item active">Create Shared Report</li>
      </ol>
    </div>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-3">
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Shared Report for {survey && <i>{survey.name}</i>}
              </div>
              {survey && <div className="card-body row fw-bold">
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-12">Report Name</label>
                  <div className="col-12">
                    <input type="text" className={"form-control mb-5px " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} autoComplete="off" />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
              </div>}
            </div>

            {(survey?.stakeholders?.length ?? 0) > 0 && <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                <i className="fa-solid fa-filter me-2"></i> Stakeholders
              </div>
              {survey?.stakeholders?.length === 0 && <div className="card-body row fw-bold">
                <p>There are no stakeholders defined for the survey {survey!.name}</p>
              </div>}
              {(survey?.stakeholders?.length ?? 0) > 0 && <>
                <div className="card-body">
                  <strong>Supression rules:</strong> Define the minimum number of responses needed in order to allow this report
                  to show report elements for each stakeholder.
                </div>
                <div className="list-group list-group-flush">
                  {survey!.stakeholders!.map((d) => <div key={`stakeholder/${d.id}`} className="list-group-item d-flex justify-content-between align-items-center">
                    <div className="form-check">
                      <input type="checkbox" checked={true} className="form-check-input" />
                      {d.name}
                    </div>
                    <div><input type="number" className="form-control form-control-sm text-end" value={d.defaultMinimumResponsesRequired} /></div>
                  </div>)}
                </div>
              </>}
            </div>}
          </div>
          <div className="col-md-9">
            {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Report District(s), Conference(s), School(s)
              </div>
              <div className="card-body">
                {(watchSchools?.length ?? 0) > 0 && <div className="row border-bottom mb-1">
                  <div className="col-3 fw-bold">Parent District/Conference</div>
                  <div className="col-3 fw-bold">School/District/Conference</div>
                  <div className="col-2 fw-bold">Contact Person</div>
                  <div className="col-2 fw-bold">Contact Role</div>
                  <div className="col-2 fw-bold">Contact Email</div>
                </div>}
                {schoolQuestion && <RenderReports schoolQuestion={schoolQuestion} prefix="" roles={roles} indent={0} reportSchools={watchSchools ?? []} />}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button type="submit" className="btn btn-success me-2">Save Report</button>
            <Link to='../..' className="btn btn-primary">Cancel</Link>
          </div>
        </div>
      </form>
    </FormProvider>

  </div>);

}

type RenderReportProps = {
  //index: number;
  // fieldName: string;
  schoolQuestion: SurveyQuestion;
  prefix: string;
  roles: string[];
  indent: number;
  parentSchool?: SurveyReportSchool;
  // selectedChoiceIds: number[];
  reportSchools: SurveyReportSchool[];
};

function RenderReports(props: RenderReportProps) {
  const { prefix, schoolQuestion, roles, indent, parentSchool, reportSchools } = props;

  const [promptAdd, setPromptAdd] = useState<boolean>();

  // const contactRolePath = `${prefix}contactRole` as 'contactRole';
  // const contactNamePath = `${prefix}contactName` as 'contactName';
  // const contactEmailPath = `${prefix}contactEmail` as 'contactEmail';
  const schoolsArrayInputPath = `${prefix}schools` as 'schools';
  const { control, register } = useFormContext<SurveyReportSchool | SurveyReport>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: schoolsArrayInputPath,
    keyName: "id"
  });


  const watchSchools = useWatch({
    control: control,
    name: schoolsArrayInputPath,
  });

  useEffect(() => {
    // ((fields.length === 0 && parentSchool === undefined) || (parentSchool?.name))
    if ((watchSchools?.length ?? 0) === 0) {
      setPromptAdd(true);
    }
  }, [watchSchools]);

  return <>
    {fields.map((field, index) => {
      //const question = questions?.find(q => q.id === field.questionId);
      const newPrefix = `${schoolsArrayInputPath}.${index}.`;
      //if (field.choiceId) {
      // School
      return <><div className={"row mb-1 align-items-center " + (index % 2 === 0 ? '' : '')}>
        <div className="col-2">{parentSchool?.choice?.choiceText ?? parentSchool?.name}</div>
        <div className="col-4">
          <div className="input-group">
            {field.choice === undefined
              ? <><button type="button" className="btn btn-sm btn-outline-primary" disabled title="District / Conference"><i className="fa-fw fa-solid fa-building"></i></button><input className="form-control" type="text" placeholder="District/conference name" {...register(`${schoolsArrayInputPath}.${index}.name` as 'name')} /></>
              : <><span className="input-group-text"><i className="fa-solid fa-school"></i></span><input className="form-control readonly" readOnly value={field.choice?.choiceText} /></>}
            <button type="button" className="btn btn-sm btn-outline-danger" disabled={(watchSchools?.at(index)?.schools?.length ?? 0) > 0} onClick={() => remove(index)}><i className="fa-solid fa-trash"></i></button>
          </div>

        </div>
        <div className="col-2">
          <input
            type="text"
            className="form-control"
            placeholder="Contact name"
            {...register(`${schoolsArrayInputPath}.${index}.contactName` as 'contactName')} />
        </div>
        <div className="col-2">
          <Typeahead
            id={`${schoolsArrayInputPath}.${index}.contactRole`}
            allowNew={true}
            placeholder="Contact role"
            {...register(`${schoolsArrayInputPath}.${index}.contactRole` as 'contactRole')}
            onChange={(selected) => {
            }}
            options={roles}
          />
        </div>
        <div className="col-2">
          <input
            type="text"
            className="form-control"
            placeholder="Contact email"
            {...register(`${schoolsArrayInputPath}.${index}.contactEmail` as 'contactEmail')}
          />
        </div>
      </div>
        {schoolQuestion && <RenderReports key={`${newPrefix}_subfields`} schoolQuestion={schoolQuestion} prefix={newPrefix} roles={roles} indent={indent + 1} parentSchool={watchSchools?.at(index)} reportSchools={reportSchools} />}
      </>;
      //}

    })}

    {/* {(parentSchool?.choiceId === undefined || fields.length === 0) &&  <RenderAddField schoolQuestion={schoolQuestion} canAddDistrict={true} append={append} indent={indent + 1} />} */}
    {((fields.length === 0 && parentSchool === undefined) || (parentSchool?.name)) && <div className="row">
      <div className="col-2">{parentSchool?.name}</div>
      <div className="col-6 d-flex flex-row justify-content-between">
        <RenderAddField schoolQuestion={schoolQuestion} canAddDistrict={true} append={append} indent={indent + 1} reportSchools={reportSchools} />
      </div>
    </div>}
  </>;
}
type RenderAddFieldProps = {
  schoolQuestion: SurveyQuestion;
  canAddDistrict: boolean;
  append: (school: SurveyReportSchool) => void;
  indent: number;
  // selectedChoiceIds: number[];
  reportSchools: SurveyReportSchool[];
};

function RenderAddField(props: RenderAddFieldProps) {
  const { schoolQuestion, canAddDistrict, append, indent, reportSchools } = props;
  const [addSchool, setAddSchool] = useState<boolean>();
  const addQuestionChoiceRef = useRef<TypeaheadRef>(null);

  return <div>
    {addSchool && schoolQuestion && <div className="input-group w-100">
      <span className="input-group-text">School:</span>
      <Typeahead
        id={`select_school-${indent}`}
        placeholder="Select a school"
        ref={addQuestionChoiceRef}
        options={schoolQuestion?.choices?.filter((choice) => !getSchoolIds(reportSchools).includes(choice.id)) ?? []}
        //options={schoolQuestion.choices ?? []}
        labelKey='choiceText'
        onChange={(selected) => {
          const item = selected as SurveyQuestionChoice[];
          if (item.length > 0) {
            const q = item[0];
            append({
              choiceId: q.id,
              choice: q,
            });
            //addQuestionRef?.current?.clear();
            addQuestionChoiceRef?.current?.clear();
            setAddSchool(undefined);
          }
        }}
      //className={" " + (errors?.values?.[index]?.choiceId ? "is-invalid" : "")}
      />
      <button type="button" className="btn btn-sm btn-outline-danger" onClick={() => setAddSchool(undefined)}><i className="fa-solid fa-times"></i></button>
    </div>}
    {addSchool === false && <span>ADD CONFERENCE</span>}
    {addSchool === undefined && <button type="button" className="btn btn-sm" onClick={() => setAddSchool(true)}><i className="fa-solid fa-plus"></i> Add School</button>}
    {canAddDistrict && addSchool === undefined && <button type="button" className="btn btn-sm" onClick={() => append({ name: 'New district/conference' })}><i className="fa-solid fa-plus"></i> Add Conference/District</button>}

  </div>;
}