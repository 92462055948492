import { RefAttributes, useEffect, useRef } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { format } from "../../helpers/format";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchSurvey, saveSurveyStakeholder, selectSurvey } from "../../store/surveySlice";
import { FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import SurveyStakeholder from "../../models/surveyStakeholder";
import { Typeahead, TypeaheadRef } from "react-bootstrap-typeahead";
import SurveyQuestion from "../../models/surveyQuestion";

interface DetailsRouteParams {
  surveyId?: string;
  surveyStakeholderId?: string;
}

export default function SurveyStakeholderScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;
  const isEditing = params.surveyStakeholderId && true;

  const addQuestionRef = useRef<TypeaheadRef>(null);

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.isLoading]);

  const form = useForm<SurveyStakeholder>();
  const { register, handleSubmit, reset, formState: { errors }, control } = form;
  const { fields, append, remove, move } = useFieldArray({
    name: "values",
    keyName: "valueId",
    control
  });

  const onSubmit: SubmitHandler<SurveyStakeholder> = data => {
    console.log(data);
    const request: SurveyStakeholder = {
      ...data,
    };

    // if (isEditing) {

    // }
    // else {
    //   dispatch(addUpdateDimension(await api.survey(survey.id).dimensions.create(request)));
    // }
    dispatch(saveSurveyStakeholder(survey!.id, request));
    navigate('../..');
    // const request: Project = {
    //   ...data,
    //   partnerId: data.partner?.id ?? data.partnerId,
    //   tags: tags?.filter(t => selectedTagIds.includes(t.id ?? 0))?.map(t => ({ tagId: t.id! })) ?? [], // selectedTags.map((t) => ({ tagId: t.id! })),
    //   attributes: data.attributes?.map((attrib) => {
    //     return {
    //       id: attrib.id,
    //       boolValue: attrib.boolValue,
    //       dateValue: attrib.dateValue,
    //       intValue: attrib.intValue,
    //       stringValue: attrib.stringValue,
    //       choicesValue: (attrib.choicesValue ?
    //         (Array.isArray(attrib.choicesValue) ? attrib.choicesValue : [attrib.choicesValue])
    //         : undefined
    //       ),
    //     };
    //   })
    // };
    // console.log(request);
    // setIsLoading(true);
    // (isEditing ? entityApi.update(request) : entityApi.create(request))
    //   .then((record) => {
    //     navigate(generatePath(ProjectDetailsRoute, { id: `${record.id}` }) + '#resources');
    //     setIsLoading(false);
    //   })
    //   .catch((reason) => {
    //     setError("Unable to load " + label.singular + ": " + reason);
    //     setIsLoading(false);
    //   });
  };

  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey) {
      let titleParts = [surveyState.survey.name, 'Surveys'];
      if (isEditing) {
        const surveyStakeholderId = parseInt(params.surveyStakeholderId);
        const edit = survey?.stakeholders?.find(d => d.id === surveyStakeholderId);
        titleParts = ['Edit Stakeholder', ...titleParts];
        reset(edit);
      }
      else {
        titleParts = ['Create Stakeholder', ...titleParts];
      }
      setTitle(titleParts);
    }
  }, [isEditing, params.surveyStakeholderId, reset, survey?.stakeholders, surveyState.isLoading, surveyState.survey]);


  const questions = survey?.questions?.filter(q => q.type === "MC");

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item">Surveys</li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item"><Link to='../..'>{surveyState.survey.name}</Link></li>}
        {isEditing ? <li className="breadcrumb-item active">Edit Stakeholder</li> : <li className="breadcrumb-item active">Create Stakeholder</li>}
      </ol>
    </div>
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-md-3">
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                <div>Stakeholder definition for {survey && <i>{survey.name}</i>}</div>
              </div>
              {survey && <div className="card-body row fw-bold">
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-3">Name</label>
                  <div className="col-md-9">
                    <input type="text" className={"form-control mb-5px " + (errors.name ? 'is-invalid' : '')} {...register("name", { required: true })} autoComplete="off" />
                    {errors.name && <div className="invalid-feedback">This field is required</div>}
                  </div>
                </div>
                <div className="row mb-15px">
                  <label className="form-label col-form-label col-md-8">Default Supression Response Count</label>
                  <div className="col-md-4">
                    <input type="number" className={"form-control mb-5px " + (errors.defaultMinimumResponsesRequired ? 'is-invalid' : '')} {...register("defaultMinimumResponsesRequired", { required: false })} />
                  </div>
                </div>
              </div>}
            </div>
          </div>
          <div className="col-md-9">
            {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
            <div className="card border-0 mb-4">
              <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
                Choice value(s)
              </div>
              <div className="card-body">
                <p>
                  A response will be attributed to this stakeholder if it matches <strong>any of the choices</strong> selected below.
                </p>
                {survey && <div className="card-body row fw-bold">
                  {fields.length === 0 && <p>
                    <span className="badge bg-warning">INSTRUCTIONS</span> Select a question from the drop-down below to get started.
                  </p>}
                  {fields.map((field, index) => {
                    const question = questions?.find(q => q.id === field.questionId);
                    return <div className="row mb-2">
                      <div className="col-auto">
                        {question?.name}
                        <div className="small">{question?.text}</div>
                      </div>
                      <div className="col-auto">
                        <select
                          className={"form-control form-control-sm " + (errors?.values?.[index]?.choiceId ? "is-invalid" : "")}
                          {...register(`values.${index}.choiceId` as const, { required: true, valueAsNumber: true, })}
                        >
                          <option></option>
                          {question?.choices?.map(c => <option value={c.id}>{c.choiceText}</option>)}
                        </select>
                        {errors?.values?.[index]?.choiceId && <div className="invalid-feedback">Please select a choice for this question.</div>}
                      </div>
                      <div className="col-1 text-end">
                        <button type="button" className="btn btn-sm btn-outline-danger" onClick={(e) => remove(index)}><i className="fa-solid fa-times"></i></button>
                      </div>
                    </div>;
                  })}
                </div>}
              </div>
              <div className="card-footer">
                {questions && <div className="input-group">
                  <div className="input-prepend">
                    <span className="input-group-text">Add Question</span>
                  </div>
                  <Typeahead
                    ref={addQuestionRef}
                    emptyLabel={'Select a question from the survey...'}
                    multiple={false}
                    labelKey="label"
                    options={questions?.map(q => {
                      return {
                        ...q,
                        label: `${q.name} - ${q.text}`,
                      };
                    })}
                    onChange={(selected) => {
                      const item = selected as SurveyQuestion[];
                      if (item.length > 0) {
                        const q = item[0];
                        append({
                          questionId: q.id,
                          questionName: `${q.name} - ${q.text}`,
                        });
                        addQuestionRef?.current?.clear();
                      }
                    }}
                  /></div>}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button type="submit" className="btn btn-success me-2">Save Stakeholder</button>
            <Link to='../..' className="btn btn-primary">Cancel</Link>
          </div>
        </div>
      </form>
    </FormProvider>

  </div>);
}