import { useEffect } from "react";
import { setTitle } from "../../util/useDocumentTitle";
import { format } from "../../helpers/format";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchSurvey, selectSurvey } from "../../store/surveySlice";
import { setSurveyDetailsTab } from "../../store/uiSlice";
import { QuestionBrowser } from "../../components/survey/questionBrowser";
import SurveyQuestion from "../../models/surveyQuestion";

export enum SurveyDetailsTabs {
  SharedReports = 1,
  Metrics = 2,
  Questions = 3,
}

interface DetailsRouteParams {
  surveyId?: string;
}

export default function SurveyDetailsScreen() {
  const dispatch = useAppDispatch();
  const ui = useAppSelector(state => state.ui.survey);
  const params = useParams<keyof DetailsRouteParams>();
  const surveyState = useAppSelector(selectSurvey);
  const survey = surveyState.survey;

  useEffect(() => {
    if (params.surveyId) {
      const surveyId = parseInt(params.surveyId);
      if (!surveyState.isLoading && !surveyState.error) {
        if (survey === undefined || survey.id !== surveyId) {
          console.log('Fetching Survey', surveyId, survey);
          dispatch(fetchSurvey(surveyId));
        }
      }
    }
  }, [dispatch, params.surveyId, survey, surveyState.error, surveyState.isLoading]);

  useEffect(() => {
    if (surveyState.isLoading) {
      setTitle(['Loading Survey Details...']);
    }
    else if (surveyState.survey) {
      setTitle([surveyState.survey.name, 'Surveys']);
    }
  }, [surveyState.isLoading, surveyState.survey]);

  const schoolQuestion = survey?.schoolQuestionId && survey?.questions?.find(q => q.id === survey?.schoolQuestionId);

  return (<div>
    <div className="d-flex align-items-center mb-3">
      <ol className="breadcrumb float-xl-end">
        <li className="breadcrumb-item"><Link to='..'>Surveys</Link></li>
        {surveyState.isLoading && <li className="breadcrumb-item">Loading...</li>}
        {surveyState.survey && <li className="breadcrumb-item">{surveyState.survey.name}</li>}
        <li className="breadcrumb-item active">Details</li>
      </ol>
    </div>
    <div className="row">
      <div className="col-md-3">
        <div className="card border-0 mb-4">
          <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
            Survey Information
          </div>
          {survey && <div className="card-body row fw-bold">
            <div className="col-auto">
              <h6 className="card-subtitle text-muted">Name</h6>
              <p>{survey?.name}</p>
            </div>

            <div className="col-auto">
              <h6 className="card-subtitle text-muted">Qualtrics ID</h6>
              <div className="card-text mb-2">
                {survey?.qualtricsId}
              </div>
            </div>

            <div className="col-auto">
              <h6 className="card-subtitle text-muted">Last Fetched</h6>
              <div className="card-text mb-2">
                {survey?.structureAsOfUtc && format.date(survey.structureAsOfUtc)}
              </div>
            </div>

            {survey.isActive === false && <div className="col-auto">
              <h6 className="card-subtitle text-muted">Status</h6>
              <p><span className="badge bg-danger">DISABLED</span></p>
            </div>}

            <div className="col-auto">
              <h6 className="card-subtitle text-muted"># Questions</h6>
              <div className="card-text mb-2 text-end">
                {format.number(survey.numberQuestions)}
              </div>
            </div>
            <div className="col-auto">
              <h6 className="card-subtitle text-muted"># Responses</h6>
              <div className="card-text mb-2 text-end">
                {format.number(survey.numberResponses)}
              </div>
            </div>

            <div className="col-auto">
              <h6 className="card-subtitle text-muted">School Question</h6>
              <div className="card-text mb-2">
                {schoolQuestion && <p title={schoolQuestion.text}>{schoolQuestion.name}</p>}
              </div>
            </div>
          </div>}
          <div className="card-footer d-flex flex-row justify-content-end">
            <Link to={'edit'} className="btn btn-sm btn-outline-primary">Edit Survey</Link>
          </div>
        </div>
        <div className="card border-0 mb-4">
          <div className="card-header bg-none h6 p-3 m-0 d-flex align-items-center">
            <i className="fa-solid fa-filter me-2"></i> Stakeholders
          </div>
          {survey?.stakeholders?.length === 0 && <div className="card-body row fw-bold">
            <p>There are no stakeholders defined for the survey {survey!.name}</p>
            <p className="text-warning">Stakeholders are "cohorts" of the responses such as Students, Adults, Parents, Teachers/Staff, Teachers, Admins, etc.
              Each stakeholder includes a "supresion rule" which will indicate the minimum number of responses that must exist in a report in order for the stakeholder to be available (e.g. if less than 10 [teachers], do not show this dimenion).
            </p>
          </div>}
          {(survey?.stakeholders?.length ?? 0) > 0 && <>
            <div className="list-group list-group-flush">
              {survey!.stakeholders!.map((d) => <Link to={`stakeholder/${d.id}/edit`} className="list-group-item d-flex justify-content-between align-items-start">
                <div>{d.name}</div>
                <span className="badge text-bg-primary rounded-pill">{d.values?.length}</span>
              </Link>)}
            </div>
          </>}
          <div className="card-footer d-flex flex-row justify-content-end">
            <Link to={'stakeholder/create'} className="btn btn-sm btn-outline-primary">Add Stakeholder</Link>
          </div>
        </div>
      </div>
      <div className="col-md-9">
        {surveyState.error && <div className="alert alert-danger"><strong>Error!</strong> {surveyState.error}</div>}
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.detailsTab === SurveyDetailsTabs.SharedReports ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setSurveyDetailsTab(SurveyDetailsTabs.SharedReports))}><i className="fa-duotone fa-solid fa-chart-user me-2"></i> Shared Reports</button>
          </li>
          <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.detailsTab === SurveyDetailsTabs.Metrics ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setSurveyDetailsTab(SurveyDetailsTabs.Metrics))}><i className="fa-duotone fa-solid fa-calculator-simple me-2"></i> Metrics</button>
          </li>
          <li className="nav-item">
            <button type="button" className={"nav-link " + (ui.detailsTab === SurveyDetailsTabs.Questions ? "active" : "")} aria-current="page" onClick={(e) => dispatch(setSurveyDetailsTab(SurveyDetailsTabs.Questions))}><i className="fa-duotone fa-solid fa-clipboard-list-check me-2"></i> Questions</button>
          </li>
        </ul>
        {ui.detailsTab === SurveyDetailsTabs.Questions && <div className="card border-0 mb-4">
          <div className="card-body">
            {survey?.questions && <QuestionBrowser
              questions={survey?.questions}
              selectedQuestionIds={[]}
              editRoute="question/:surveyQuestionId/edit"
            />}
          </div>
        </div>}
        {ui.detailsTab === SurveyDetailsTabs.SharedReports && <div className="card border-0 mb-4">
          {survey && (survey.reports?.length ?? 0) === 0 && <div className="card-body row fw-bold">
            <p>There are no shared reports for the survey {survey.name}</p>
          </div>}
          {survey?.reports && <div className="card-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <td className="text-end"></td>
                  <td className="text-end">Actions</td>
                </tr>
              </thead>
              <tbody>
                {survey.reports.map((report) => <tr key={`report-${report.id}`}>
                  <td><Link to={`report/${report.id}/edit`}>{report.name}</Link></td>
                  <td className="text-end">

                  </td>
                  <td className="text-end"><Link to={`report/${report.id}/edit`} className="btn btn-sm btn-outline-primary">Edit</Link></td>
                </tr>)}
              </tbody>
            </table>
          </div>}
          <div className="card-footer d-flex flex-row justify-content-end">
            {survey?.schoolQuestionId 
              ? <Link to='report/create' className="btn btn-sm btn-outline-primary">Add Shared Report</Link> 
              : <p>You must specify the School question before creating a report</p>}
          </div>
        </div>}

        {ui.detailsTab === SurveyDetailsTabs.Metrics && <div className="card border-0 mb-4">
          {survey && (survey.metrics?.length ?? 0) === 0 && <div className="card-body row fw-bold">
            <p>There are no metrics defined for the survey {survey.name}</p>
          </div>}
          {(survey?.metrics?.length ?? 0) > 0 && <div className="card-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <td className="text-end"># Questions</td>
                  <td className="text-end">Actions</td>
                </tr>
              </thead>
              <tbody>

                {survey!.metrics!.map((metric) => <tr key={`metric-${metric.id}`}>
                  <td><Link to={`metric/${metric.id}/edit`}>{metric.name}</Link></td>
                  <td className="text-end">{metric.questions?.length}</td>
                  <td className="text-end"><Link to={`metric/${metric.id}/edit`} className="btn btn-sm btn-outline-primary">Edit</Link></td>
                </tr>)}
              </tbody>
            </table>
          </div>}
          <div className="card-footer d-flex flex-row justify-content-end">
            <Link to='metric/create' className="btn btn-sm btn-outline-primary">Add Metric</Link>
          </div>
        </div>}
      </div>
    </div>
  </div>);
}